<template>
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.39014 0.887939H5.93414L0.414135 6.33594L5.93414 11.7839H9.39014L3.84614 6.33594L9.39014 0.887939Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "ArrowLeft"
}
</script>

<style>

</style>
