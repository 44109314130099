<template>
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 0H3C2.20436 0 1.44118 0.315985 0.878678 0.878678C0.315964 1.44118 0 2.20436 0 3V14.25C0 15.0456 0.315985 15.8088 0.878678 16.3713C1.44118 16.934 2.20436 17.25 3 17.25H21C21.7956 17.25 22.5588 16.934 23.1213 16.3713C23.684 15.8088 24 15.0456 24 14.25V3C24 2.20436 23.684 1.44118 23.1213 0.878678C22.5588 0.315964 21.7956 0 21 0ZM22.5 14.25C22.5 14.6478 22.342 15.0293 22.0608 15.3108C21.7793 15.592 21.3978 15.75 21 15.75H2.99999C2.60218 15.75 2.22069 15.592 1.93922 15.3108C1.65797 15.0293 1.49998 14.6478 1.49998 14.25V3C1.49998 2.60219 1.65797 2.2207 1.93922 1.93923C2.22068 1.65798 2.60217 1.49999 2.99999 1.49999H21C21.3978 1.49999 21.7793 1.65798 22.0608 1.93923C22.342 2.22069 22.5 2.60218 22.5 3V14.25Z"
              fill="black"/>
        <path d="M19.7776 3.91989L12.0017 10.2799L4.22584 3.91989C3.90524 3.65727 3.4325 3.70435 3.16989 4.02494C2.90727 4.34553 2.95435 4.81828 3.27494 5.08088L11.5249 11.8309C11.8014 12.0575 12.1994 12.0575 12.4758 11.8309L20.7258 5.08088C21.0464 4.81826 21.0935 4.34551 20.8309 4.02494C20.5682 3.70437 20.0955 3.65726 19.7749 3.91989H19.7776Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "SendMailIcon"
}
</script>

<style>

</style>
