<template>
  <main-layout>
    <div id="back-btn" class="calendarPage__titleContainer">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
    </div>
    <div class="blogSinglePage__image-container">
      <picture v-if="post">
        <source
          type="image/webp"
          :srcset="`${pathToImage(post.slug, post.image)}.webp`"
        />
        <img
          class="blogSinglePage__image"
          :src="`${pathToImage(post.slug, post.image)}.png`"
          :alt="`${post.slug} image`"
        />
      </picture>
    </div>
    <div v-if="post" class="blogSinglePage">
      <h1>{{ post.title }}</h1>
      <div class="d-flex align-items-center justify-content-between mb-20">
        <div class="d-flex flex-wrap">
          <div v-for="tag in post.tags" :key="tag.id" class="blogPage__tag">
            {{ tag.name }}
          </div>
        </div>
        <div class="blogSinglePage__dataHeader">
          <div class="me-2">
            <data-formatted date-format="dd.MM.yyyy " :data="post.date" />
          </div>
          <div class="blogCard__author">
            {{ $t("by") }}&nbsp;
            <span class="text-decoration-underline">
              <short-name
                :name="
                  !!post.expert
                    ? `${post.expert.first_name} ${post.expert.last_name}`
                    : null
                "
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        <span v-html="post.content"></span>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mt-30 mb-30"
      >
        <div class="d-flex align-items-center">
          <avatar
            v-if="!!post.expert"
            width="60px"
            height="60px"
            user-role="experts"
          />
          <img
            v-else
            class="p-0 avatar experts__avatar"
            src="@/styles/img/default/aura-impact-logo.png"
            alt="Aura Impact logo"
          />
          <div class="ml-15">
            <div class="blogSinglePage__dataFooter">
              <data-formatted date-format="dd.MM.yyyy" :data="post.date" />
              &nbsp;{{ $t("by") }}&nbsp;
              <span class="text-decoration-underline">
                <short-name
                  :name="
                    !!post.expert
                      ? `${post.expert.first_name} ${post.expert.last_name}`
                      : null
                  "
                />
              </span>
            </div>
            <div v-if="!!post.expert" class="blogSinglePage__position">
              {{ post.expert.position }}
            </div>
          </div>
        </div>
        <!--        <base-button class="blogSinglePage__btn">-->
        <!--          <send-mail-icon/>-->
        <!--          Share article via email-->
        <!--        </base-button>-->
      </div>
      <div class="blogSinglePage__navigation">
        <!--        <div><span class="leftArrow"><horizontal-arrow/></span> {{ $t('prevArticle') }}</div>-->
        <!--        <div>{{ $t('nextArticle') }} <span class="rightArrow"><horizontal-arrow/> </span></div>-->
      </div>
    </div>
    <h5
      v-if="blogPosts && blogPosts.length > 0"
      class="blogSinglePage__related"
    >
      {{ $t("relatedArticle") }}
    </h5>
    <div v-if="blogPosts" class="row">
      <div v-for="post in blogPosts" class="col-lg-6">
        <blog-card
          :preview="this.loginUser.role_id === 1"
          :upload-path="path"
          :post="post"
        />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import DataDay from "@/components/components-helpers/DataDay";
import Avatar from "@/components/UI/images/Avatar";
import BaseButton from "@/components/UI/buttons/BaseButton";
import SendMailIcon from "@/components/UI/icons/SendMailIcon";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import ShortName from "@/components/components-helpers/ShortName";
import ArrowLeft from "@/components/UI/icons/arrows/ArrowLeft";
import BlogCard from "@/components/elements/card/BlogCard";
import { mapGetters } from "vuex";

export default {
  name: "BlogSinglePage",
  components: {
    BlogCard,
    ArrowLeft,
    ShortName,
    DataFormatted,
    HorizontalArrow,
    SendMailIcon,
    BaseButton,
    Avatar,
    DataDay,
    MainLayout,
  },

  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
      post: null,
      blogPosts: null,
    };
  },
  mounted() {
    this.getBlogPost();
  },
  methods: {
    getBlogPost() {
      this.$http
        .get(`/blog/posts/slug/${this.$route.params.blog}`)
        .then(({ data }) => {
          this.post = data.body;
          let tagId = data.body.tags[0] ? data.body.tags[0].id : null;
          this.getRelatedArticles(tagId);
        });
    },
    getRelatedArticles(tags) {
      let param = tags ? tags : null;
      this.$http
        .get("/blog/posts", {
          params: {
            tag: param,
            limit: 2,
          },
        })
        .then(({ data }) => {
          this.blogPosts = data.body.filter((post) => post.id !== this.post.id);
          this.blogPosts = this.blogPosts.filter((post, i) => i < 2);
        });
    },
    pathToImage(slug, imageName) {
      return `${this.path}/posts/${slug}/${imageName}-1000`;
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.getBlogPost();
      },
    },
  },
};
</script>

<style></style>
